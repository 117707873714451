import { FIELD_TYPES } from "constants/template-field-type";
export default {
    title: {
        type: FIELD_TYPES.TEXT,
        label: "Title",
        textArea: true,
        translatable: true
    },
    titleColor: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Title color"
    }
};
