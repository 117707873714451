function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import buttonConfig from "segments/desktop/core-components/button/signup-button/fields-config.js";
const { signupWithsolutionAppReferenceId, signupClusterId } = buttonConfig, restSignupButtonConfig = _object_without_properties(buttonConfig, [
    "signupWithsolutionAppReferenceId",
    "signupClusterId"
]);
export default {
    theme: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Theme"
    },
    clusters: {
        type: FIELD_TYPES.MULTIPLE_NESTED,
        label: "Clusters",
        maxLimit: 9,
        nestedConfig: {
            type: FIELD_TYPES.NESTED,
            fields: {
                text: {
                    type: FIELD_TYPES.TEXT,
                    label: "Text",
                    translatable: true
                },
                image: {
                    type: FIELD_TYPES.GALLERY,
                    label: "Image"
                },
                colorName: {
                    type: FIELD_TYPES.COLOR_PICKER,
                    label: "Color"
                },
                clusterId: {
                    type: FIELD_TYPES.CLUSTERS,
                    label: "Cluster Id"
                },
                useCaseId: {
                    type: FIELD_TYPES.SUB_CLUSTERS,
                    label: "Usecase Id"
                },
                initiallySelected: {
                    type: FIELD_TYPES.BOOLEAN,
                    label: "Initially selected"
                }
            }
        }
    },
    buttonConfig: {
        type: FIELD_TYPES.NESTED,
        label: "Signup button",
        fields: restSignupButtonConfig
    }
};
