function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import ProcessTabsConfig from "segments/desktop/core-components/process-tabs/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
import VerticalTimerTabsConfig from "segments/desktop/core-components/vertical-timer-tabs/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
const { buttonConfig, showButton, progressbarColor, backgroundColor, tabs: { nestedConfig: { fields: { timer } } } } = VerticalTimerTabsConfig, nestedTabsConfig = _object_without_properties(VerticalTimerTabsConfig.tabs.nestedConfig.fields, [
    "timer"
]), tabsConfig = _object_without_properties(VerticalTimerTabsConfig.tabs, [
    "nestedConfig"
]), rest = _object_without_properties(VerticalTimerTabsConfig, [
    "buttonConfig",
    "showButton",
    "progressbarColor",
    "backgroundColor",
    "tabs"
]);
const { textAlign, mobileTextAlign, buttonConfig: titleButtonConfig, title, titleColor, textColor, bodyColor, topic } = FullParagraphFieldsConfig, resOfParagraphConfig = _object_without_properties(FullParagraphFieldsConfig, [
    "textAlign",
    "mobileTextAlign",
    "buttonConfig",
    "title",
    "titleColor",
    "textColor",
    "bodyColor",
    "topic"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Side By Side Tabs And Process",
    fields: _object_spread(_object_spread_props(_object_spread({}, ThemeConfig), {
        title: {
            type: FIELD_TYPES.NESTED,
            label: "Title",
            fields: _object_spread_props(_object_spread({}, TitleConfig), {
                titleMargin: {
                    label: "Title bottom margin",
                    type: FIELD_TYPES.TEXT
                }
            })
        },
        selectedTabBackgroundColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Selected tab background color (for mobile)"
        },
        tabs: _object_spread({
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Tabs",
            nestedConfig: _object_spread({
                type: FIELD_TYPES.NESTED,
                fields: {
                    title: {
                        type: FIELD_TYPES.TEXT,
                        label: "Tab Title",
                        translatable: true
                    },
                    overrideTabFontColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Title Font Color"
                    },
                    content: {
                        type: FIELD_TYPES.NESTED,
                        label: "Tab Full Paragraph",
                        fields: _object_spread_props(_object_spread({}, resOfParagraphConfig), {
                            buttonConfig: titleButtonConfig
                        })
                    },
                    processTab: ProcessTabsConfig
                }
            }, nestedTabsConfig)
        }, tabsConfig)
    }), rest)
};
