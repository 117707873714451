import { FIELD_TYPES } from "constants/template-field-type";
export default {
    tagsWidth: {
        type: FIELD_TYPES.TEXT,
        label: "Tags width (max 170px, min 140px)"
    },
    buttonConfig: {
        type: FIELD_TYPES.NESTED,
        label: "Signup Button",
        fields: {
            removeGradient: {
                type: FIELD_TYPES.BOOLEAN,
                label: "Remove gradient"
            }
        }
    },
    showBorderInLightTheme: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Add tags border in white theme"
    },
    randomizeTags: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Randomize tags"
    }
};
