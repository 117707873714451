import { FIELD_TYPES } from "constants/template-field-type";
import buttonConfig from "segments/desktop/core-components/button/fields-config";
import AssetFieldsConfig from "components/core/asset/fields-config";
import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";
export default {
    tabs: {
        type: FIELD_TYPES.MULTIPLE_NESTED,
        label: "Tabs",
        maxLimit: 8,
        nestedConfig: {
            type: FIELD_TYPES.NESTED,
            fields: {
                title: {
                    type: FIELD_TYPES.TEXT,
                    label: "Tab Title",
                    translatable: true
                },
                content: {
                    type: FIELD_TYPES.TEXT,
                    label: "Tab Content",
                    textArea: true,
                    rows: 5,
                    translatable: true
                },
                timer: {
                    type: FIELD_TYPES.TEXT,
                    label: "Tab Timer Length (in milliseconds)"
                },
                badgeText: {
                    type: FIELD_TYPES.TEXT,
                    label: "Badge text (if needed)",
                    translatable: true
                },
                asset: {
                    type: FIELD_TYPES.NESTED,
                    label: "Asset",
                    fields: AssetFieldsConfig
                }
            }
        }
    },
    backgroundColor: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Background Color"
    },
    progressbarColor: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Progress Bar Color"
    },
    assetPosition: {
        type: FIELD_TYPES.COMBO,
        label: "Asset Position",
        options: [
            {
                value: positionTypes.TOP_LEFT,
                text: "Top Left"
            },
            {
                value: positionTypes.TOP_CENTER,
                text: "Top Center"
            },
            {
                value: positionTypes.TOP_RIGHT,
                text: "Top Right"
            },
            {
                value: positionTypes.CENTER_LEFT,
                text: "Center Left"
            },
            {
                value: positionTypes.CENTER_CENTER,
                text: "Center Center"
            },
            {
                value: positionTypes.CENTER_RIGHT,
                text: "Center Right"
            },
            {
                value: positionTypes.BOTTOM_LEFT,
                text: "Bottom Left"
            },
            {
                value: positionTypes.BOTTOM_CENTER,
                text: "Bottom Center"
            },
            {
                value: positionTypes.BOTTOM_RIGHT,
                text: "Bottom Right"
            }
        ]
    },
    showButton: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Show Button"
    },
    isTabsFullWidth: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Tabs full width"
    },
    buttonConfig
};
