import { FIELD_TYPES } from "constants/template-field-type";
import { BACKGROUND_COLOR_OPTIONS } from "segments/segments-service";
import { AMETHYST_PURPLE_COLOR, DEEP_NAVY_BLUE_COLOR, INDIGO_BLUE_COLOR, MIDNIGHT_PURPLE_COLOR, SAPPHIRE_PURPLE_COLOR } from "styles/color-consts";
export default {
    gradientTheme: {
        type: FIELD_TYPES.MULTIPLE_NESTED,
        label: "Override gradient theme color",
        maxLimit: 3,
        nestedConfig: {
            type: FIELD_TYPES.COLOR_PICKER,
            fields: {
                color: {
                    label: "Color",
                    type: FIELD_TYPES.COLOR_PICKER,
                    possibleColors: [
                        ...BACKGROUND_COLOR_OPTIONS,
                        MIDNIGHT_PURPLE_COLOR,
                        INDIGO_BLUE_COLOR,
                        AMETHYST_PURPLE_COLOR,
                        SAPPHIRE_PURPLE_COLOR,
                        DEEP_NAVY_BLUE_COLOR
                    ]
                }
            }
        }
    }
};
