function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
function _object_destructuring_empty(o) {
    if (o === null || o === void 0) throw new TypeError("Cannot destructure " + o);
    return o;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import gridImageFieldsConfig from "segments/desktop/core-components/grid-image/fields-config";
import { REGULAR_BUTTON_TYPE, SECONDARY_BUTTON_TYPE, SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import RegularButtonFieldsConfig from "segments/desktop/core-components/button/regular-button/fields-config";
import SecondaryButtonFieldsConfig from "segments/desktop/core-components/button/secondary-button/fields-config";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
const restGridFields = _extends({}, _object_destructuring_empty(gridImageFieldsConfig));
export default _object_spread_props(_object_spread({}, restGridFields), {
    showParagraph: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Show paragraph"
    },
    fullParagraph: {
        type: FIELD_TYPES.NESTED,
        label: "Full Paragraph",
        fields: _object_spread_props(_object_spread({}, FullParagraphFieldsConfig), {
            buttonConfig: {
                type: FIELD_TYPES.CONDITIONAL_NESTED,
                label: "Button",
                conditionalKeyName: "buttonType",
                options: [
                    {
                        text: REGULAR_BUTTON_TYPE,
                        fieldsConfig: RegularButtonFieldsConfig
                    },
                    {
                        text: SECONDARY_BUTTON_TYPE,
                        fieldsConfig: SecondaryButtonFieldsConfig
                    },
                    {
                        text: SIGNUP_BUTTON_TYPE,
                        fieldsConfig: SignupButtonFieldsConfig
                    }
                ]
            }
        })
    }
});
