import { FIELD_TYPES } from "constants/template-field-type";
export default {
    src: {
        type: FIELD_TYPES.GALLERY,
        defaultImagesKeys: [],
        label: "Select an Image"
    },
    alt: {
        type: FIELD_TYPES.TEXT,
        label: "Image description (alt)",
        translatable: true
    },
    isDecorative: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Decorative image(no alt)"
    },
    link: {
        type: FIELD_TYPES.NESTED,
        label: "Image link",
        fields: {
            href: {
                type: FIELD_TYPES.TEXT,
                label: "Image href"
            },
            isNewTab: {
                type: FIELD_TYPES.BOOLEAN,
                label: "Open link in new tab"
            }
        }
    }
};
