import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
export default {
    type: FIELD_TYPES.NESTED,
    fields: {
        quote: {
            type: FIELD_TYPES.NESTED,
            label: "Text",
            fields: TitleConfig
        },
        author: {
            type: FIELD_TYPES.TEXT,
            label: "Author Name"
        },
        company: {
            type: FIELD_TYPES.NESTED,
            label: "Author Company",
            fields: TitleConfig
        },
        asset: {
            type: FIELD_TYPES.NESTED,
            label: "Author Picture",
            fields: AssetFieldsConfig.image.fields
        },
        backgroundColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Background Color"
        }
    }
};
