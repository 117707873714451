function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import ParagraphBodyConfig from "segments/desktop/core-components/paragraph/fields-config";
import CarouselFieldsConfig from "segments/desktop/core-components/carousel/fields-config";
import AssetFieldsConfig from "components/core/asset/fields-config";
import { PRODUCT_OPTIONS } from "constants/products";
import { CRM_DARK_TINT_01_COLOR_NAME, CRM_DARKER_TINT_02_COLOR_NAME, DEV_LIGHTER_TINT_01_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
const { autoplay, autoplaySpeed } = CarouselFieldsConfig;
const { image } = AssetFieldsConfig; // videos will be supported in the future
export default {
    type: FIELD_TYPES.NESTED,
    label: "Use Cases",
    fields: {
        theme: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Theme"
        },
        buttonsColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Buttons color",
            possibleColors: [
                WHITE_COLOR_NAME,
                CRM_DARK_TINT_01_COLOR_NAME,
                CRM_DARKER_TINT_02_COLOR_NAME,
                DEV_LIGHTER_TINT_01_COLOR_NAME
            ]
        },
        autoplay,
        autoplaySpeed,
        scrollWhenButtonClicked: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Scroll when a tab button is clicked"
        },
        tabs: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Tabs",
            maxLimit: 10,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    tabLabel: {
                        type: FIELD_TYPES.TEXT,
                        label: "Tab Label",
                        translatable: true
                    },
                    tabId: {
                        type: FIELD_TYPES.TEXT,
                        label: "Tab Id"
                    },
                    tabContent: {
                        type: FIELD_TYPES.NESTED,
                        label: "Tab Content",
                        fields: {
                            asset: {
                                type: FIELD_TYPES.NESTED,
                                label: "Asset",
                                fields: {
                                    image
                                }
                            },
                            mobileAsset: {
                                type: FIELD_TYPES.NESTED,
                                label: "Mobile asset",
                                fields: {
                                    image
                                }
                            },
                            mobileTextAlign: {
                                type: FIELD_TYPES.COMBO,
                                label: "Paragraphs Mobile Text Align",
                                options: [
                                    {
                                        value: "left",
                                        text: "Left"
                                    },
                                    {
                                        value: "center",
                                        text: "Center"
                                    },
                                    {
                                        value: "right",
                                        text: "Right"
                                    }
                                ]
                            },
                            images: {
                                type: FIELD_TYPES.MULTIPLE_NESTED,
                                label: "Paragraphs",
                                maxLimit: 3,
                                nestedConfig: {
                                    type: FIELD_TYPES.NESTED,
                                    fields: {
                                        fullParagraph: {
                                            type: FIELD_TYPES.NESTED,
                                            label: "Full Paragraph",
                                            fields: _object_spread({}, TitleConfig, ParagraphBodyConfig)
                                        }
                                    }
                                }
                            },
                            banner: {
                                type: FIELD_TYPES.NESTED,
                                label: "Banner",
                                fields: {
                                    productType: {
                                        label: "Product",
                                        type: FIELD_TYPES.COMBO,
                                        options: PRODUCT_OPTIONS
                                    },
                                    title: {
                                        type: FIELD_TYPES.TEXT,
                                        label: "Title",
                                        translatable: true
                                    },
                                    body: {
                                        type: FIELD_TYPES.TEXT,
                                        label: "Body",
                                        translatable: true
                                    },
                                    buttonText: {
                                        type: FIELD_TYPES.TEXT,
                                        label: "Button Text",
                                        translatable: true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
};
