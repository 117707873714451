function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { handleServerException } from "../server/services/exceptions-handling-service/exceptions-handling-service";
const fetchAllRequestsAndPopulateDynamicData = function() {
    var _ref = _async_to_generator(function*({ segmentsRequest, dynamicData }) {
        let hasUpdated = false;
        (yield Promise.all(segmentsRequest)).forEach(({ id, fetchRes, error, propsPropertyName })=>{
            if (!error) {
                hasUpdated = true;
                dynamicData[id] = _object_spread_props(_object_spread({}, dynamicData[id] || {}), {
                    [propsPropertyName]: fetchRes
                });
            }
        });
        return hasUpdated;
    });
    return function fetchAllRequestsAndPopulateDynamicData(_) {
        return _ref.apply(this, arguments);
    };
}();
const getAllAvailableDynamicRequestsData = ({ pageConfig, segmentsDynamicsFetchMapper })=>{
    const requests = (pageConfig.segments || []).reduce((accRequests, segment)=>{
        const { type: segmentLayoutType, props: segmentLayoutProps, id: segmentLayoutId } = segment;
        const dynamicFetchRequests = segmentsDynamicsFetchMapper[segmentLayoutType];
        if (dynamicFetchRequests) {
            dynamicFetchRequests.forEach((fetchRequest)=>{
                const { shouldFetch, fetch, propsPropertyName } = fetchRequest;
                accRequests.push({
                    shouldFetch,
                    fetch,
                    propsPropertyName,
                    segmentLayoutProps,
                    segmentLayoutId,
                    segmentLayoutType
                });
            });
        }
        ;
        return accRequests;
    }, []);
    return requests;
};
const collectAllRequestsForClient = ({ pageConfig, segmentsDynamicsFetchMapper, previousPageConfig })=>{
    const previousPropsBySegmentIds = (previousPageConfig.segments || []).reduce((acc, { id, props })=>{
        acc[id] = props;
        return acc;
    }, {});
    const allRequests = getAllAvailableDynamicRequestsData({
        pageConfig,
        segmentsDynamicsFetchMapper
    });
    const segmentsRequest = allRequests.reduce((accRequests, request)=>{
        const { shouldFetch, fetch, propsPropertyName, segmentLayoutProps, segmentLayoutId } = request;
        if (shouldFetch({
            props: segmentLayoutProps,
            previousProps: previousPropsBySegmentIds[segmentLayoutId] || {},
            pageConfig,
            previousPageConfig
        })) {
            accRequests.push(_async_to_generator(function*() {
                try {
                    const fetchRes = yield fetch({
                        props: segmentLayoutProps,
                        pageConfig,
                        isClientFetch: true
                    });
                    return {
                        id: segmentLayoutId,
                        fetchRes,
                        propsPropertyName
                    };
                } catch (error) {
                    return {
                        error
                    };
                }
            })());
        }
        return accRequests;
    }, []);
    return segmentsRequest;
};
const collectAllRequestsForServer = ({ pageConfig, segmentsDynamicsFetchMapper, req, res, query })=>{
    const allRequests = getAllAvailableDynamicRequestsData({
        pageConfig,
        segmentsDynamicsFetchMapper
    });
    const segmentsRequest = allRequests.reduce((accRequests, request)=>{
        const { fetch, propsPropertyName, segmentLayoutProps, segmentLayoutId, segmentLayoutType } = request;
        accRequests.push(_async_to_generator(function*() {
            try {
                const fetchRes = yield fetch({
                    props: segmentLayoutProps,
                    pageConfig,
                    isClientFetch: false,
                    query
                });
                return {
                    id: segmentLayoutId,
                    fetchRes,
                    propsPropertyName
                };
            } catch (error) {
                const extraLogParams = {
                    segmentLayoutType,
                    segmentLayoutId,
                    fetchProperty: propsPropertyName
                };
                handleServerException({
                    externalMessage: "Faild to fetch dynamic data on template generator",
                    e: error,
                    req,
                    res,
                    extraLogParams
                });
                return {
                    error
                };
            }
        })());
        return accRequests;
    }, []);
    return segmentsRequest;
};
export const fetchDynamicSegmentsRequestFromServer = function() {
    var _ref = _async_to_generator(function*({ pageConfig = {}, segmentsDynamicsFetchMapper, req, res, query }) {
        const dynamicData = {};
        try {
            const segmentsRequest = collectAllRequestsForServer({
                pageConfig,
                segmentsDynamicsFetchMapper,
                req,
                res,
                query
            });
            yield fetchAllRequestsAndPopulateDynamicData({
                segmentsRequest,
                dynamicData
            });
        } catch (error) {
            handleServerException({
                externalMessage: "Fatal error: Faild in the proccess of fetching dynamic data on template generator",
                e: error,
                req,
                res,
                query
            });
        }
        return {
            dynamicData
        };
    });
    return function fetchDynamicSegmentsRequestFromServer(_) {
        return _ref.apply(this, arguments);
    };
}();
export const fetchDynamicSegmentsRequestFromClient = function() {
    var _ref = _async_to_generator(function*({ pageConfig = {}, previousPageConfig = {}, segmentsDynamicsFetchMapper, previousDynamicData = {} }) {
        const dynamicData = _object_spread({}, previousDynamicData);
        const segmentsRequest = collectAllRequestsForClient({
            pageConfig,
            segmentsDynamicsFetchMapper,
            previousPageConfig
        });
        const hasUpdated = yield fetchAllRequestsAndPopulateDynamicData({
            segmentsRequest,
            dynamicData
        });
        return {
            hasUpdated,
            dynamicData
        };
    });
    return function fetchDynamicSegmentsRequestFromClient(_) {
        return _ref.apply(this, arguments);
    };
}();
export const getDynamicPropsBySegmentLayoutId = (dynamicData, id)=>{
    return dynamicData && dynamicData[id] || {};
};
export const getFaqData = ({ pageConfig, req, res })=>{
    let faq = [];
    try {
        faq = (pageConfig.segments || []).reduce((accFaq, segment)=>{
            const { type, props } = segment;
            if (type === "FrequentlyAskedQuestionsPricingComponent") {
                accFaq.push(...props.questions);
            }
            return accFaq;
        }, []);
    } catch (error) {
        handleServerException({
            externalMessage: "Faild to get FAQ data from segments",
            e: error,
            req,
            res
        });
    }
    return faq;
};
