import { FIELD_TYPES } from "constants/template-field-type";
import FaqQuestionFieldsConfig from "segments/desktop/core-components/faq-question/fields-config";
import { LG, LGR, MD, SM, XL, XS, XS_L, XXL } from "constants/sizes";
import { BACKGROUND_COLOR_OPTIONS } from "segments/segments-service";
import { SNOW_GREY_THEME_ID } from "segments/segments-service";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Frequently Asked Questions",
    fields: {
        theme: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Theme",
            possibleColors: [
                ...BACKGROUND_COLOR_OPTIONS,
                SNOW_GREY_THEME_ID
            ]
        },
        title: {
            type: FIELD_TYPES.TEXT,
            label: "Title (text or translation key)",
            translatable: true
        },
        titleColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Title color"
        },
        titleSize: {
            type: FIELD_TYPES.COMBO,
            label: "Title Size",
            options: [
                {
                    value: XS,
                    text: "22px (formerly 24px)"
                },
                {
                    value: SM,
                    text: "28px (formerly 32px)"
                },
                {
                    value: MD,
                    text: "36px (formerly 40px)"
                },
                {
                    value: LG,
                    text: "44px (formerly 50px)"
                },
                {
                    value: LGR,
                    text: "54px (formerly 60px)"
                },
                {
                    value: XL,
                    text: "64px (formerly 72px)"
                },
                {
                    value: XXL,
                    text: "72px (formerly 80px)"
                }
            ]
        },
        questionSize: {
            type: FIELD_TYPES.COMBO,
            label: "Question text Size",
            options: [
                {
                    value: XS_L,
                    text: "18px"
                },
                {
                    value: XS,
                    text: "22px (formerly 24px)"
                },
                {
                    value: SM,
                    text: "28px (formerly 32px)"
                },
                {
                    value: MD,
                    text: "36px (formerly 40px)"
                },
                {
                    value: LG,
                    text: "44px (formerly 50px)"
                },
                {
                    value: LGR,
                    text: "54px (formerly 60px)"
                },
                {
                    value: XL,
                    text: "64px (formerly 72px)"
                },
                {
                    value: XXL,
                    text: "72px (formerly 80px)"
                }
            ]
        },
        answerSize: {
            type: FIELD_TYPES.COMBO,
            label: "Answer text Size",
            options: [
                {
                    value: MD,
                    text: "18px (formerly 20px)"
                },
                {
                    value: LG,
                    text: "22px (formerly 24px)"
                }
            ]
        },
        questions: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Questions",
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: FaqQuestionFieldsConfig
            }
        },
        hideQuestionsJumpToShortCut: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Hide questions jump-To shortcut"
        }
    }
};
