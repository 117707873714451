function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import VerticalTabsFieldsConfig from "segments/desktop/core-components/vertical-tabs/fields-config";
import AssetFieldsConfig from "components/core/asset/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
const { backgroundColor, theme, tabsColor, tabs, title } = VerticalTabsFieldsConfig, restOfConfig = _object_without_properties(VerticalTabsFieldsConfig, [
    "backgroundColor",
    "theme",
    "tabsColor",
    "tabs",
    "title"
]);
const { topic, buttonConfig, textAlign, body, textColor, titleColor, color } = FullParagraphFieldsConfig, restOfParagraphFields = _object_without_properties(FullParagraphFieldsConfig, [
    "topic",
    "buttonConfig",
    "textAlign",
    "body",
    "textColor",
    "titleColor",
    "color"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Timeline",
    fields: _object_spread_props(_object_spread({
        theme,
        tabsColor,
        titlesColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Titles Color"
        },
        lineColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Menu line Color"
        }
    }, restOfConfig), {
        contentHeight: {
            type: FIELD_TYPES.TEXT,
            label: "Content height (optional)"
        },
        contentGap: {
            type: FIELD_TYPES.TEXT,
            label: "Content gap"
        },
        titles: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Tabs Title",
            maxLimit: 4,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    tabsTitle: {
                        type: FIELD_TYPES.TEXT,
                        label: "Tabs title"
                    },
                    tabs: {
                        type: FIELD_TYPES.MULTIPLE_NESTED,
                        label: "Tabs",
                        maxLimit: 8,
                        nestedConfig: {
                            type: FIELD_TYPES.NESTED,
                            fields: {
                                tabLabel: {
                                    type: FIELD_TYPES.TEXT,
                                    label: "Tab Label",
                                    translatable: true
                                },
                                title: {
                                    type: FIELD_TYPES.NESTED,
                                    label: "Asset text",
                                    fields: restOfParagraphFields
                                },
                                asset: {
                                    type: FIELD_TYPES.NESTED,
                                    label: "Asset",
                                    fields: _object_spread({
                                        assetVerticalPadding: {
                                            type: FIELD_TYPES.TEXT,
                                            label: "Asset Vertical padding",
                                            translatable: true
                                        }
                                    }, AssetFieldsConfig)
                                }
                            }
                        }
                    }
                }
            }
        }
    })
};
