function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import { BRAND_DARK_COLOR_THEME_ID, SPACE_BLUE_GRADIANT_THEME_ID } from "segments/segments-service";
import SquareTagsFieldsConfig from "segments/desktop/core-components/square-tags/fields-config";
import { BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02, BRAND_BLACK_BACKGROUND_COLOR, BRAND_DARK_COLOR, BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01, CRM_DARKER_TINT_02, WHITE_COLOR_NAME } from "styles/color-consts";
import { PALLET_VALUES } from "segments/desktop/homepage-first-fold/layouts/monday-universe-homepage-first-fold/palette-schemes";
const { textAlign, mobileTextAlign, textColor, topic, buttonConfig: button } = FullParagraphFieldConfig, restFullParagraphFieldsConfig = _object_without_properties(FullParagraphFieldConfig, [
    "textAlign",
    "mobileTextAlign",
    "textColor",
    "topic",
    "buttonConfig"
]);
const { theme } = SquareTagsFieldsConfig, restSquareTagsFieldsConfig = _object_without_properties(SquareTagsFieldsConfig, [
    "theme"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Monday Universe",
    fields: _object_spread_props(_object_spread({
        theme: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Theme",
            possibleColors: [
                SPACE_BLUE_GRADIANT_THEME_ID,
                BRAND_DARK_COLOR_THEME_ID,
                CRM_DARKER_TINT_02,
                BRAND_BLACK_BACKGROUND_COLOR,
                WHITE_COLOR_NAME,
                BRAND_DARK_COLOR,
                BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02,
                BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01
            ]
        },
        pallet: {
            type: FIELD_TYPES.COMBO,
            label: "Color Palette",
            options: [
                {
                    value: PALLET_VALUES.CRM_WHITE,
                    text: `CRM palette - for ${WHITE_COLOR_NAME} background`
                },
                {
                    value: PALLET_VALUES.CRM_GREEN,
                    text: `CRM palette - for ${CRM_DARKER_TINT_02} background`
                },
                {
                    value: PALLET_VALUES.DEV_WHITE,
                    text: `Dev palette - for ${WHITE_COLOR_NAME} background`
                },
                {
                    value: PALLET_VALUES.DEV_BLACK,
                    text: `Dev palette - for ${BRAND_BLACK_BACKGROUND_COLOR} background`
                },
                {
                    value: PALLET_VALUES.WM_WHITE,
                    text: `WM palette - for ${WHITE_COLOR_NAME} / ${BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01} background`
                },
                {
                    value: PALLET_VALUES.WM_BLACK,
                    text: `WM palette - for ${BRAND_BLACK_BACKGROUND_COLOR} / ${BRAND_DARK_COLOR} background`
                },
                {
                    value: PALLET_VALUES.WM_PURPLE,
                    text: `WM palette - for ${BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02} background`
                },
                {
                    value: PALLET_VALUES.DEV_OLD,
                    text: `Dev palette - old for ${BRAND_DARK_COLOR} background`
                }
            ]
        },
        fullParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Full paragraph",
            fields: restFullParagraphFieldsConfig
        },
        subTitle: {
            type: FIELD_TYPES.NESTED,
            label: "Sub title",
            fields: TitleConfig
        }
    }, restSquareTagsFieldsConfig), {
        lines: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Lines",
            maxLimit: 3,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    start: {
                        type: FIELD_TYPES.NESTED,
                        label: "Start point",
                        fields: {
                            top: {
                                type: FIELD_TYPES.TEXT,
                                label: "Top"
                            },
                            left: {
                                type: FIELD_TYPES.TEXT,
                                label: "Left"
                            }
                        }
                    },
                    end: {
                        type: FIELD_TYPES.NESTED,
                        label: "End point",
                        fields: {
                            top: {
                                type: FIELD_TYPES.TEXT,
                                label: "Top"
                            },
                            left: {
                                type: FIELD_TYPES.TEXT,
                                label: "Left"
                            }
                        }
                    }
                }
            }
        }
    })
};
