import { FIELD_TYPES } from "constants/template-field-type";
import RegularButtonFieldsConfig from "segments/desktop/core-components/button/regular-button/fields-config";
import SecondaryButtonFieldsConfig from "segments/desktop/core-components/button/secondary-button/fields-config";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
import TwoLinkButtonsButtonConfig from "segments/desktop/core-components/button/two-link-buttons-button/fields-config";
import SignupComboButtonsButtonConfig from "segments/desktop/core-components/signup-combo-buttons-button/fields-config";
import SubscribeButtonConfig from "segments/desktop/core-components/button/subscribe-button/fields-config";
import YouTubePopupButtonConfig from "segments/desktop/core-components/button/youtube-popup-button/fields-config";
import SignupAndYoutubeComboButtonConfig from "segments/desktop/core-components/button/signup-and-youtube-combo-button/fields-config";
import { REGULAR_BUTTON_TYPE, SECONDARY_BUTTON_TYPE, SIGNUP_BUTTON_TYPE, TWO_LINK_BUTTONS_TYPE, SUBSCRIBE_BUTTON_TYPE, YOUTUBE_POPUP_LINK_TYPE, SIGNUP_COMBO_BUTTONS_TYPE, SIGNUP_AND_YOUTUBE_COMBO_BUTTONS_TYPE } from "./button-consts";
export default {
    type: FIELD_TYPES.CONDITIONAL_NESTED,
    label: "Button",
    conditionalKeyName: "buttonType",
    options: [
        {
            text: REGULAR_BUTTON_TYPE,
            fieldsConfig: RegularButtonFieldsConfig,
            type: FIELD_TYPES.NESTED
        },
        {
            text: SECONDARY_BUTTON_TYPE,
            fieldsConfig: SecondaryButtonFieldsConfig,
            type: FIELD_TYPES.NESTED
        },
        {
            text: SIGNUP_BUTTON_TYPE,
            fieldsConfig: SignupButtonFieldsConfig,
            type: FIELD_TYPES.NESTED
        },
        {
            text: TWO_LINK_BUTTONS_TYPE,
            fieldsConfig: TwoLinkButtonsButtonConfig,
            type: FIELD_TYPES.NESTED
        },
        {
            text: SIGNUP_COMBO_BUTTONS_TYPE,
            fieldsConfig: SignupComboButtonsButtonConfig,
            type: FIELD_TYPES.NESTED
        },
        {
            text: SUBSCRIBE_BUTTON_TYPE,
            fieldsConfig: SubscribeButtonConfig,
            type: FIELD_TYPES.NESTED
        },
        {
            text: YOUTUBE_POPUP_LINK_TYPE,
            fieldsConfig: YouTubePopupButtonConfig,
            type: FIELD_TYPES.NESTED
        },
        {
            text: SIGNUP_AND_YOUTUBE_COMBO_BUTTONS_TYPE,
            fieldsConfig: SignupAndYoutubeComboButtonConfig,
            type: FIELD_TYPES.NESTED
        }
    ]
};
