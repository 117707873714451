function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import GridParagraphWithImageFieldsConfig from "segments/desktop/core-components/grid-paragraph-with-image/fields-config";
import { BACKGROUND_COLOR_OPTIONS } from "segments/segments-service";
import ThemeConfig from "segments/core-configs/theme-config";
import { ALIGN_TYPES } from "constants/align-types";
import AssetFieldsConfig from "components/core/asset/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import { GAINSBORO_COLOR_NAME, TRANSPARENT_COLOR_NAME } from "styles/color-consts";
import { COLUMN_WIDTH, MARGIN_BETWEEN_CARDS } from "./flexible-cards-consts";
const { image } = AssetFieldsConfig;
const { src } = image.fields;
const mobileImage = _object_spread_props(_object_spread({}, image), {
    label: "Mobile image"
});
const backgroundImage = _object_spread_props(_object_spread({}, image), {
    label: "Card background image",
    fields: _object_spread_props(_object_spread({
        backgroundPosition: {
            type: FIELD_TYPES.COMBO,
            label: "Background position",
            options: [
                "left",
                "center",
                "right"
            ]
        }
    }, image.fields), {
        src: _object_spread_props(_object_spread({}, src), {
            getFullUrl: true
        })
    })
});
const backSideBackgroundImage = _object_spread_props(_object_spread({}, image), {
    label: "Card back side background image",
    fields: _object_spread_props(_object_spread({
        backgroundPosition: {
            type: FIELD_TYPES.COMBO,
            label: "Background position",
            options: [
                "left",
                "center",
                "right"
            ]
        }
    }, image.fields), {
        src: _object_spread_props(_object_spread({}, src), {
            getFullUrl: true
        })
    })
});
export default {
    type: FIELD_TYPES.NESTED,
    label: "Cards",
    fields: _object_spread_props(_object_spread({}, ThemeConfig), {
        borderColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Border color",
            possibleColors: [
                ...BACKGROUND_COLOR_OPTIONS,
                GAINSBORO_COLOR_NAME
            ]
        },
        shadowCards: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Add shadow to all cards"
        },
        removeShadowOnHover: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Remove shadow on hover"
        },
        gridAlignment: {
            type: FIELD_TYPES.COMBO,
            label: "Grid alignment",
            options: [
                ALIGN_TYPES.LEFT,
                ALIGN_TYPES.CENTER,
                ALIGN_TYPES.RIGHT
            ]
        },
        innerPadding: {
            type: FIELD_TYPES.TEXT,
            label: "Inner padding"
        },
        gridItems: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Grid Items",
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: _object_spread_props(_object_spread({
                    cardColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Card color",
                        possibleColors: [
                            ...BACKGROUND_COLOR_OPTIONS,
                            TRANSPARENT_COLOR_NAME
                        ]
                    },
                    backgroundImage,
                    cardNumOfColumnsWidth: {
                        type: FIELD_TYPES.COMBO,
                        label: "Card width",
                        options: [
                            {
                                text: `25% (recommended image width: ${COLUMN_WIDTH}px)`,
                                value: 1
                            },
                            {
                                text: `33% (recommended image width: ${COLUMN_WIDTH * 1.33 + MARGIN_BETWEEN_CARDS}px)`,
                                value: 1.33
                            },
                            {
                                text: `33% - small version (recommended image width: ${COLUMN_WIDTH * 1.2 + MARGIN_BETWEEN_CARDS}px)`,
                                value: 1.2
                            },
                            {
                                text: `50% (recommended image width: ${COLUMN_WIDTH * 2 + MARGIN_BETWEEN_CARDS}px)`,
                                value: 2
                            },
                            {
                                text: `66% (recommended image width: ${COLUMN_WIDTH * 2.66 + MARGIN_BETWEEN_CARDS}px)`,
                                value: 2.66
                            },
                            {
                                text: `75% (recommended image width: ${COLUMN_WIDTH * 3 + MARGIN_BETWEEN_CARDS * 2}px)`,
                                value: 3
                            },
                            {
                                text: `100% (recommended image width: ${COLUMN_WIDTH * 4 + MARGIN_BETWEEN_CARDS * 3}px)`,
                                value: 4
                            }
                        ]
                    },
                    paragraphVerticalColoredLineColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Paragraph vertical colored line color",
                        possibleColors: BACKGROUND_COLOR_OPTIONS
                    }
                }, GridParagraphWithImageFieldsConfig), {
                    mobileImage,
                    cardTagText: {
                        type: FIELD_TYPES.TEXT,
                        label: "Card tag text"
                    },
                    cardTagColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Card tag color",
                        possibleColors: BACKGROUND_COLOR_OPTIONS
                    },
                    addBackLayerContent: {
                        type: FIELD_TYPES.BOOLEAN,
                        label: "Add background content"
                    },
                    footer: {
                        type: FIELD_TYPES.NESTED,
                        label: "Footer",
                        fields: {
                            showFooter: {
                                type: FIELD_TYPES.BOOLEAN,
                                label: "Show footer"
                            },
                            image: {
                                type: FIELD_TYPES.GALLERY,
                                label: "Footer image"
                            },
                            imageWidth: {
                                type: FIELD_TYPES.TEXT,
                                label: "Footer image width"
                            },
                            text: {
                                type: FIELD_TYPES.TEXT,
                                label: "Footer text",
                                textArea: true,
                                rows: 5
                            }
                        }
                    },
                    cardBackLayerContent: {
                        type: FIELD_TYPES.NESTED,
                        label: "Card back layer content",
                        fields: {
                            contentVerticalAlignment: {
                                type: FIELD_TYPES.COMBO,
                                label: "Content Vertical Alignment",
                                options: [
                                    "top",
                                    "center",
                                    "bottom"
                                ]
                            },
                            backSideBackgroundImage,
                            backLayerFullParagraph: {
                                type: FIELD_TYPES.NESTED,
                                label: "Full paragraph",
                                fields: _object_spread({}, FullParagraphFieldsConfig)
                            }
                        }
                    }
                })
            }
        }
    })
};
