function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import { BACKGROUND_COLOR_OPTIONS } from "segments/segments-service";
import { XS, SM, MD, LG, LGR, XL, XXL } from "constants/sizes";
import AssetFieldsConfig from "components/core/asset/fields-config";
const { image } = AssetFieldsConfig;
const { src } = image.fields;
const { textColor } = FullParagraphFieldsConfig, selectedFullParagraphFields = _object_without_properties(FullParagraphFieldsConfig, [
    "textColor"
]);
export default {
    swapTextAndAssetPosition: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Swap Text And Asset Position - Desktop"
    },
    mobileSwapTextAndAssetPosition: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Swap Form and Texts position - Mobile"
    },
    hideParagraph: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Hide paragraph"
    },
    paragraphVerticalAlignment: {
        type: FIELD_TYPES.COMBO,
        label: "Paragraph Vertical Alignment",
        options: [
            "top",
            "center",
            "bottom"
        ]
    },
    alignImageToScreenEdge: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Align Image To Screen Edge"
    },
    background: {
        type: FIELD_TYPES.NESTED,
        label: "Background",
        fields: {
            width: {
                type: FIELD_TYPES.COMBO,
                label: "Width",
                options: [
                    "50%",
                    "75%",
                    "100%"
                ]
            },
            color: {
                type: FIELD_TYPES.COLOR_PICKER,
                label: "Color",
                possibleColors: BACKGROUND_COLOR_OPTIONS
            },
            verticalPadding: {
                type: FIELD_TYPES.TEXT,
                label: "Vertical Padding"
            },
            backgroundImage: _object_spread_props(_object_spread({}, image), {
                label: "Background image",
                fields: _object_spread_props(_object_spread({
                    backgroundPosition: {
                        type: FIELD_TYPES.COMBO,
                        label: "Background position",
                        options: [
                            "left",
                            "center",
                            "right"
                        ]
                    }
                }, image.fields), {
                    src: _object_spread_props(_object_spread({}, src), {
                        getFullUrl: true
                    })
                })
            })
        }
    },
    fullParagraph: {
        type: FIELD_TYPES.NESTED,
        label: "Full Paragraph",
        fields: selectedFullParagraphFields
    },
    titleSize: {
        type: FIELD_TYPES.COMBO,
        label: "Title Size",
        options: [
            {
                value: XS,
                text: "22px (formerly 24px)"
            },
            {
                value: SM,
                text: "28px (formerly 32px)"
            },
            {
                value: MD,
                text: "36px (formerly 40px)"
            },
            {
                value: LG,
                text: "44px (formerly 50px)"
            },
            {
                value: LGR,
                text: "54px (formerly 60px)"
            },
            {
                value: XL,
                text: "64px (formerly 72px)"
            },
            {
                value: XXL,
                text: "72px (formerly 80px)"
            }
        ]
    },
    asset: {
        type: FIELD_TYPES.NESTED,
        label: "Asset",
        fields: _object_spread({
            position: {
                type: FIELD_TYPES.COMBO,
                label: "Position",
                options: [
                    "top",
                    "center",
                    "bottom"
                ]
            },
            fitToContainer: {
                type: FIELD_TYPES.BOOLEAN,
                label: "Fit to container"
            },
            assetHeight: {
                type: FIELD_TYPES.TEXT,
                label: "Height"
            }
        }, AssetFieldsConfig)
    }
};
