import { FIELD_TYPES } from "constants/template-field-type";
import { MD, LG, MS, SM } from "constants/sizes";
export default {
    textSize: {
        type: FIELD_TYPES.COMBO,
        label: "Text Size",
        options: [
            {
                value: SM,
                text: "14px"
            },
            {
                value: MS,
                text: "16px"
            },
            {
                value: MD,
                text: "18px (formerly 20px)"
            },
            {
                value: LG,
                text: "22px (formerly 24px)"
            }
        ]
    },
    textWidth: {
        type: FIELD_TYPES.TEXT,
        label: "Text width"
    },
    withDynamicParagraphTitle: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Dynamic paragraph Title"
    },
    withDynamicParagraphSubtitle: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Dynamic paragraph Subtitle"
    }
};
