import { FIELD_TYPES } from "constants/template-field-type";
import { PARAGRAPH_AND_ASSET_VIDEOS } from "constants/video-types";
export default {
    borderRadius: {
        type: FIELD_TYPES.TEXT,
        label: "Border Radius",
        labelDescription: "Set the border radius for both image and video (e.g., '10px', '50%')"
    },
    image: {
        type: FIELD_TYPES.NESTED,
        label: "Image",
        fields: {
            src: {
                type: FIELD_TYPES.GALLERY,
                defaultImagesKeys: [
                    "static/img/templates/long-template/boards/"
                ],
                label: "Select an Image"
            },
            alt: {
                type: FIELD_TYPES.TEXT,
                label: "Image description (alt)",
                translatable: true
            },
            isDecorative: {
                type: FIELD_TYPES.BOOLEAN,
                label: "Decorative image(no alt)"
            },
            href: {
                type: FIELD_TYPES.TEXT,
                label: "Href"
            }
        }
    },
    video: {
        type: FIELD_TYPES.NESTED,
        label: "Video",
        fields: {
            src: {
                type: FIELD_TYPES.CLOUDINARY_VIDEO_PICKER,
                label: "Video",
                videoType: PARAGRAPH_AND_ASSET_VIDEOS
            },
            ariaLabel: {
                type: FIELD_TYPES.TEXT,
                label: "Video description (accessibility)",
                translatable: true
            },
            loop: {
                type: FIELD_TYPES.BOOLEAN,
                label: "Loop"
            },
            playWhenVisible: {
                type: FIELD_TYPES.BOOLEAN,
                label: "Play Only When Visible"
            },
            disableAutoplay: {
                type: FIELD_TYPES.BOOLEAN,
                label: "Disable Autoplay (Autoplay will be disabled only if controls aren't hidden)"
            }
        }
    }
};
