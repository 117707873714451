import { FIELD_TYPES } from "constants/template-field-type";
export default {
    question: {
        type: FIELD_TYPES.TEXT,
        label: "Question text / translation key",
        translatable: true
    },
    answer: {
        type: FIELD_TYPES.TEXT,
        label: "Answer text / translation key",
        translatable: true
    }
};
