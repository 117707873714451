function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import buttonConfig from "segments/desktop/core-components/button/fields-config";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import ParagraphBodyConfig from "segments/desktop/core-components/paragraph/fields-config";
import { asset } from "components/templates-generator/config/components/asset/asset-config";
import AssetFieldsConfig from "components/core/asset/fields-config";
const { image } = asset.fields;
const assetUnderParagraph = {
    type: FIELD_TYPES.CONDITIONAL_NESTED,
    label: "Asset Under Paragraph",
    conditionalKeyName: "Add Asset Under Paragraph",
    options: [
        {
            text: "Yes",
            fieldsConfig: _object_spread_props(_object_spread({}, AssetFieldsConfig), {
                marginBetweenParagraphAndAsset: {
                    type: FIELD_TYPES.TEXT,
                    label: "Margin Between Paragraph And Asset"
                }
            }),
            type: FIELD_TYPES.NESTED
        },
        {
            text: "No",
            fieldsConfig: {},
            type: FIELD_TYPES.NESTED
        }
    ]
};
export default _object_spread_props(_object_spread({
    textAlign: {
        type: FIELD_TYPES.COMBO,
        label: "Desktop Text Align",
        options: [
            {
                value: "left",
                text: "Left"
            },
            {
                value: "center",
                text: "Center"
            },
            {
                value: "right",
                text: "Right"
            }
        ]
    },
    mobileTextAlign: {
        type: FIELD_TYPES.COMBO,
        label: "Mobile Text Align (if different)",
        options: [
            {
                value: "left",
                text: "Left"
            },
            {
                value: "center",
                text: "Center"
            },
            {
                value: "right",
                text: "Right"
            }
        ]
    },
    textColor: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Old text Color (Don't use - deprecating)",
        possibleColors: [
            "white",
            "black"
        ]
    },
    topic: {
        type: FIELD_TYPES.NESTED,
        label: "Topic",
        fields: {
            text: {
                type: FIELD_TYPES.TEXT,
                label: "Text",
                labelDescription: "Selecting an image would replace the text with it",
                translatable: true
            },
            color: {
                type: FIELD_TYPES.COLOR_PICKER,
                label: "Text Color"
            },
            borderColor: {
                type: FIELD_TYPES.COLOR_PICKER,
                label: "Border Color"
            },
            image: _object_spread_props(_object_spread({}, image), {
                fields: _object_spread_props(_object_spread({}, image.fields), {
                    height: {
                        type: FIELD_TYPES.TEXT,
                        label: "Height"
                    }
                })
            }),
            allowTextAndImage: {
                type: FIELD_TYPES.BOOLEAN,
                label: "Allow Text and Image"
            },
            gap: {
                type: FIELD_TYPES.TEXT,
                label: "Gap between image and text"
            }
        }
    }
}, TitleConfig, ParagraphBodyConfig), {
    buttonWidth: {
        type: FIELD_TYPES.TEXT,
        label: "Button Width"
    },
    buttonConfig,
    assetUnderParagraph
});
